<template>
  <div>
    <el-popover
      v-loading="loading"
      placement="right"
      width="600"
      :teleported="false"
      popper-class="menu-icon"
      trigger="click"
      @after-leave="onHide"
    >
      <div>
        <el-row
          v-if="!showDialog"
          :gutter="10"
          class="list scroller1"
          style="max-height: 600px; overflow-y: auto"
        >
          <el-col
            v-for="(item, index) in list"
            :key="index"
            :span="12"
            :xs="6"
            style="padding: 10px 30px"
          >
            <div
              @click="onSelect(item)"
              :class="[
                {
                  selected:
                    (item && item._id == value) ||
                    (!item && (!value || !value.length)),
                },
              ]"
              style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 100%; height: 84px; border: 1px solid #dddddd; border-radius: 5px; box-sizing: border-box"
            >
              <div
                v-if="item"
                style="display: flex; flex-direction: column; justify-content: center; align-items: center"
              >
                <img :src="item.img" class="avatar" />
                <div
                  style="width: 100%; text-align: center; font-size: 12px; color: #8c939d"
                >
                  {{ item.name }}
                </div>
              </div>
              <div
                v-if="item"
                style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-left: 10px"
                @click.stop="test"
              >
                <a href="javascript:void(0)" @click="edit(item)">修改</a>
                <el-popconfirm
                  title="确定删除吗？"
                  @confirm="remove(item)"
                  style="margin-top: 10px"
                >
                  <a href="javascript:void(0)" slot="reference">删除</a>
                </el-popconfirm>
              </div>

              <div
                v-if="!item"
                style="height: 100%; width: 100%; display: flex; flex-direction: row; justify-content: center; align-items: center"
              >
                无称号
              </div>
            </div>
          </el-col>

          <el-col :span="12" :xs="6" style="padding: 10px 30px">
            <div
              @click="add"
              style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 100%; height: 84px; border: 1px solid #dddddd; border-radius: 5px"
            >
              <div
                style="height: 100%; width: 100%; display: flex; flex-direction: row; justify-content: center; align-items: center"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </div>
          </el-col>
        </el-row>
        <div
          v-else
          style="width: 100%; display: flex; flex-direction: row; justify-content: center; align-items: center"
        >
          <el-form
            ref="dataForm"
            :model="dataForm"
            label-width="80px"
            style="width: fit-content"
          >
            <el-form-item
              label="称号名称"
              required
              prop="name"
              :rules="[
                {
                  required: true,
                  message: '请输入名称',
                  trigger: ['blur', 'change'],
                },
                {
                  min: 1,
                  max: 10,
                  message: '长度在 1 到 10 个字符',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                ref="name"
                placeholder="请输入名称"
                v-model="dataForm.name"
                maxlength="10"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item label="称号类型" prop="img" id="img">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                :before-upload="beforeTopImageUpload"
                action="#"
                :http-request="httpUploadRequest"
              >
                <img v-if="dataForm.img" :src="dataForm.img" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="save">确定</el-button>
              <el-button @click="showDialog = false">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <template #reference>
        <slot></slot>
      </template>
    </el-popover>

    <!--    <el-dialog v-loading="loading" :title="action == 1 ? '添加' : '修改'" v-if="action > 0" :visible.sync="showDialog" width="550px">-->

    <!--    </el-dialog>-->
  </div>
</template>

<script>
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
export default {
  name: "titleManager",
  emits: ["onSelect"],
  props: {
    value: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      list: [],
      showDialog: false,
      dataForm: {
        _id: "",
        name: "",
        img: "",
      },
    };
  },
  methods: {
    test() {},
    onHide() {
      this.showDialog = false;
    },
    onSelect(item) {
      this.$emit("onSelect", item);
    },
    async reloadTitles() {
      this.loading = true;
      let { data, errorCode } = await this.$http.armory.titles.list(this.type);
      if (errorCode != "0000") {
        return;
      }

      this.list = data;
      this.list.unshift(null);
      this.loading = false;
    },
    beforeTopImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().title + this.type + "/images/" + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.dataForm.img = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    add() {
      this.dataForm.name = "";
      this.dataForm._id = "";
      this.dataForm.img = "";
      this.showDialog = true;
    },
    edit(item) {
      this.dataForm.name = item.name;
      this.dataForm._id = item._id;
      this.dataForm.img = item.img;
      this.showDialog = true;
    },
    async remove(item) {
      this.loading = true;
      let { data, errorCode } = await this.$http.armory.titles.remove(
        this.type,
        { _id: item._id }
      );
      this.loading = false;
      if (errorCode != "0000") {
        return;
      }

      let index = this.list.indexOf(item);
      if (index >= 0) {
        this.list.splice(index, 1);
      }
    },
    save() {
      let self = this;
      this.$refs.dataForm.validate(async (valid) => {
        if (valid) {
          self.loading = true;
          let saveData = {
            ...self.dataForm,
            name: self.dataForm.name.trim(),
          };

          if (
            self.list.find(
              (r) => r && r._id != saveData._id && r.name == saveData.name
            )
          ) {
            self.$message.error("存在重复名称的称号");
            return;
          }

          let { data, errorCode } = await self.$http.armory.titles.save(
            this.type,
            saveData
          );
          self.loading = false;
          if (errorCode != "0000") {
            self.$message.error("保存失败");
            return;
          }

          self.$message.success("保存成功");
          let exist = self.list.find((r) => r && r._id == data._id);
          if (exist) {
            exist.name = data.name;
            exist.img = data.img;
          } else {
            self.list.push(data);
          }

          self.showDialog = false;
        }
      });
    },
  },
  mounted() {
    this.reloadTitles();
  },
};
</script>

<style scoped>
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 156px;
  height: 44px;
  line-height: 44px;
  text-align: center;
}
/deep/ .avatar {
  width: 156px;
  height: 44px;
  display: block;
}

.list a {
  color: #444444;
}

.list a:hover {
  color: #444444;
}

.selected {
  border: 2px solid #0066cc !important;
}
</style>
